//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

//Fonts
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Poppins Regular"), local("Poppins-Regular"), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

//Standard Colours:

$white: #fff;
$black: #000;

$lightGrey: #f1f1f1;
$lightGreyBorder: #eee;
$mediumGrey: #888;
$darkGrey: #555;
$darkestGrey: #202122;

$lightBlue: #00aede;
$mediumBlue: #0475d1;
$darkBlue: #0443a2;
$darkestBlue: #2b2d50;

$lightRed: #e63757;
$darkRed: #781d2d;

$lightGreen: #169f62;
$mediumGreen: #18aa6a;
$darkGreen: #127d4e;

$lightWarn: #fcdd32;
$mediumWarn: #d36a24;
$darkInfo: #1e5b6d;

$fontFamily: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

//Mixins:

@mixin inset-border-blue {
	border-top-color: #00608a !important;
	border-left-color: #00608a !important;
	border-right-color: $lightBlue !important;
	border-bottom-color: $lightBlue !important;
}

@mixin inset-border-red {
	border-top-color: #320c13 !important;
	border-left-color: #320c13 !important;
	border-right-color: $darkRed !important;
	border-bottom-color: $darkRed !important;
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: $lightGrey;
	border-radius: 0.375rem !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $mediumGrey;
	background: rgba($black, 0.2);
	border-radius: 0.375rem !important;
	border: 1px solid $darkGrey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $darkGrey;
	background: rgba($black, 0.4);
	border-radius: 0.375rem !important;
}

.loader-chartSized {
	height: 400px;
}

.maximised {
	height: 100vh;
}

.maximised .RRT__container {
	height: calc(100vh - 63px);
}

.maximised .RRT__panel {
	overflow: auto;
	height: 100%;
}

#chart-div {
	height: calc(100vh - 400px);
}
.maximised #chart-div {
	height: calc(100vh - 150px);
}

.RRT__panel {
	border-radius: 0.375rem !important;
}

#zone-chart-div .RRT__panel {
	height: fit-content;
	padding-bottom: 20px;
}

#zone-chart-div .extra-height {
	padding-bottom: 60px !important;
}

#toolbar-offset {
	display: block;
	position: absolute;
	bottom: 0;
	width: 95%;
}

#chart-toolbar {
	position: relative;
}

.RRT__tabs {
	overflow: auto !important;
	flex-wrap: nowrap !important;
}

.react-checkbox-tree {
	overflow: auto !important;
	flex-wrap: nowrap !important;
}

.MuiTable-NoPad th {
	text-align: center;
}

.MuiTableCell-alignLeft {
	text-align: center !important;
}

.MuiTable-NoPad .MuiTableCell-root {
	padding: 0 !important;
}

.MuiTable-VerticalMiddle .MuiTableCell-root {
	vertical-align: middle !important;
}

.MuiTable-Small .MuiTableCell-root {
	padding: 0 !important;
}

.MuiTable-Small .MuiTableCell-head {
	padding-left: 10px !important;
}

.MuiTable-Small .MuiTableCell-paddingCheckbox {
	width: 10px !important;
	padding: 0 !important;
}

.MuiTableRow-head {
	border-top: 1px solid rgb(224, 224, 224);
	th {
		background-color: $lightGrey !important;
	}
}

.MuiButtonBase-root {
	text-align: center;
	padding-left: 10px;
}

.MuiTable-root tr:hover {
	background-color: $mediumGrey;
	background-color: rgba($darkGrey, 0.2);
}

.MuiIconButton-label svg:hover {
	fill: url(#gradient-icons) $lightBlue !important;
}

thead .MuiButtonBase-root {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
	font-size: 0.9rem;
}

thead .MuiButtonBase-root {
	padding-left: 28px !important;
}

.MuiSvgIcon-root {
	font-size: 1.3rem !important;
}

.overlay-parameter {
	height: 22px;
	width: 22px;
	margin: 0;
}

.overlay-parameter > input {
	margin-left: 1px;
	margin-top: 1px;
}

.input-button-radius {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.tab-content {
	border-radius: 0 0.25rem 0.25rem 0.25rem;
}

.nav-tabs {
	border-bottom: none;
	a {
		border: none !important;
	}
	li {
		background: $lightGrey;
		background-color: rgba($lightGrey, 0.8);
		border-width: 1px 1px 1px 0;
		font-size: 14px;
		border: 1px solid $mediumGrey;
		border-left: none;
		border-bottom: none;
		.active {
			background-color: $white !important;
			font-weight: 700;
			color: $black !important;
			border: 1px solid $lightBlue !important;
			border-bottom-width: 0px !important;
			border-radius: inherit;
			-webkit-box-shadow: 0px -2px 1px 0px rgba(0, 174, 222, 0.6);
			-moz-box-shadow: 0px -2px 1px 0px rgba(0, 174, 222, 0.6);
			box-shadow: 0px -2px 1px 0px rgba(0, 174, 222, 0.6);
		}
	}
}

.react-tabs__tab--selected {
	font-weight: 700;
	color: $black !important;
	border: 1px solid $mediumBlue !important;
	border-bottom-width: 0px !important;
	border-radius: 5px 5px 0px 0px;
	-webkit-box-shadow: 0px -2px 1px 0px rgba(0, 174, 222, 0.6);
	-moz-box-shadow: 0px -2px 1px 0px rgba(0, 174, 222, 0.6);
	box-shadow: 0px -2px 1px 0px rgba(0, 174, 222, 0.6);
}

.nav-tabs li:first-child {
	border-radius: 0.25rem 0 0 0 !important;
	border-left: 1px solid $mediumGrey;
	.active {
		border-radius: 0.25rem 0 0 0 !important;
	}
}

.nav-tabs li:last-child {
	border-radius: 0 0.25rem 0 0 !important;
	.active {
		border-radius: 0 0.25rem 0 0 !important;
	}
}

.department-alarm-user {
	background: none;
	background-color: $darkBlue;
	background-image: -webkit-linear-gradient(-45deg, $darkBlue, $lightBlue);
	background-image: -o-linear-gradient(-45deg, $darkBlue, $lightBlue);
	background-image: linear-gradient(-45deg, $darkBlue, $lightBlue);
	background-position: center;
	border: none;
}

.alarm-user {
	background: none;
	background-color: $darkGreen;
	background-image: -webkit-linear-gradient(-45deg, $darkGreen, $lightGreen);
	background-image: -o-linear-gradient(-45deg, $darkGreen, $lightGreen);
	background-image: linear-gradient(-45deg, $darkGreen, $lightGreen);
	background-position: center;
	border: none;
}

.btn-falcon-success {
	color: $lightGreen !important;
}

// Navbar

.nav .nav-item .nav-link div {
	white-space: nowrap;
	max-width: 200px;
}

// Toasts

.Toastify__toast {
	border-radius: 0.375rem !important;
}

// Utilities

.subtitle {
	color: $lightBlue;
}

.react-datepicker-popper {
	min-width: 327.22px;
}

.dropdown-submenu {
	position: relative;
}

#submenu-toggle {
	font-size: 13.3333px;
	line-height: 19.9999px;
}

#submenu-pin button {
	font-size: 13.3333px;
	line-height: 19.9999px;
}

.submenu-hide {
	display: none;
}

.submenu-show {
	display: block;
}

.btn-shadow {
	-webkit-box-shadow: 0 0 0 1px rgba($darkestBlue, 0.1), 0 2px 5px 0 rgba($darkestBlue, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
	box-shadow: 0 0 0 1px rgba($darkestBlue, 0.1), 0 2px 5px 0 rgba($darkestBlue, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
}

.bg-gradient {
	background-image: linear-gradient(-45deg, $mediumBlue, $lightBlue);
	background-position: center;
}

h1 {
	color: $mediumBlue;
}

.btn-primary {
	background-color: $mediumBlue;
	border-color: $mediumBlue;
}

.pin-button {
	cursor: pointer;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 0.1875rem 0.45rem;
	font-size: 0.675rem;
	line-height: 1.2;
	border-radius: 0.2rem;
}

.menu-pin {
	background: none;
	background-image: none;
}

.menu-pin-white {
	background-color: none;
	background-image: none;
	color: $white;
}

.menu-border-blue {
	border: 1px solid $lightBlue;
	@include inset-border-blue;
}

.menu-border-red {
	border: 1px solid $lightRed;
	@include inset-border-red;
}

.menu-pin:hover {
	color: $darkGrey;
}

.pin-rotate {
	transform: rotate(-20deg);
	color: $lightBlue;
}

.menu-border-red .pin-rotate {
	transform: rotate(-20deg);
	color: $darkRed;
}

.meter-no-data {
	font-size: 1.8rem;
}

.meter-timestamp {
	font-size: 1rem;
}

.dashboard-delete {
	background: none;
	background-image: none;
	border: none;
	width: 35px;
	color: $darkRed;
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "Comic Sans MS", Arial, Helvetica, sans-serif;
}

.dashboard-delete:hover {
	background: none;
	color: $darkGrey;
	border: none;
}

.dashboard-delete:active {
	background: none;
	color: $darkGrey;
	border: none;
}
.dashboard-delete.dropdown-toggle {
	background: none !important;
	border: none;
}

.dashboard-delete.dropdown-toggle::after {
	color: rgba($black, 0);
}
.delete-item-icon {
	color: $darkRed;
}

.dash-popover .popover {
	border: 1px solid $lightBlue;
	@include inset-border-blue;
	-webkit-box-shadow: 0 0 0 1px rgba($darkestBlue, 0.1), 0 2px 5px 0 rgba($darkestBlue, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
	box-shadow: 0 0 0 1px rgba($darkestBlue, 0.1), 0 2px 5px 0 rgba($darkestBlue, 0.08), 0 1px 1.5px 0 rgba($black, 0.07), 0 1px 2px 0 rgba($black, 0.08);
}

.dash-gauge svg {
	margin-left: auto;
	margin-right: auto;
}

.dark-button {
	background-color: $white;
	color: $black;
}

.dark-button:hover {
	background-color: $darkestBlue;
}

.danger-button {
	background-color: $white;
}

.danger-button:hover {
	background-color: $lightRed;
}

.current-alarm-chart {
	border: none;
	color: $darkRed;
	background-color: transparent;
	margin-right: -10px;
	margin-top: 10px;
}

.current-alarm-chart:hover {
	background-color: transparent;
}

.alarm-chart-selected {
	border: 1px solid $darkRed;
	@include inset-border-red;
	color: $mediumGrey;
	background-color: $white;
	margin-right: -10px;
	margin-top: 10px;
}

.current-alarm-bell {
	margin-top: auto;
	margin-bottom: auto;
}

.page-link.undefined {
	background: none;
	background-color: none;
	border: 1px inset $darkBlue !important;
	background-image: linear-gradient(-45deg, $darkBlue, $lightBlue);
	background-position: center;
}

.page-link:hover {
	background-color: $lightBlue;
	background-color: rgba($lightBlue, 0.5);
	color: $black;
}

.block-flex {
	flex: 1;
}

.btn-circle {
	width: 30px;
	height: 30px;
	padding: 6px 0px;
	border-radius: 15px;
	text-align: center;
	font-size: 12px;
	line-height: 1.42857;
}

#parameter-cards .card {
	min-width: 200px;
	box-shadow: none;
}

#parameter-cards .card button {
	height: 110%;
}

.light-param-box {
	color: $darkestGrey;
}

.light-param-box:hover {
	color: $darkestGrey;
}

.light-param-box:active {
	color: $darkGrey;
}

.is-selected {
	color: $darkBlue !important;
}

.zone-button {
	background-color: $lightBlue;
	border-color: $lightBlue;
}

.button-is-selected {
	background-color: $darkBlue !important;
}

#zone-graphic-div .justify-content-between .col {
	overflow-wrap: break-word;
}

#zone-graphic-div .rct-checkbox {
	display: none;
}

#zone-chart-div .rct-checkbox {
	display: none;
}

#chart-report-div .rct-checkbox {
	display: none;
}

.RRT__tab {
	&[aria-selected="true"] {
		border: 1px solid $lightBlue;
		@include inset-border-blue;
		background-color: $white;
		font-weight: 600;
		color: $black;
	}
	&[aria-selected="false"] {
		background-color: $white;
		background-color: rgba($lightGrey, 0.8);
	}
}

.custom-control-label::before {
	border-color: $lightBlue;
	@include inset-border-blue;
	background-color: transparent;
	width: 1.25em;
	height: 1.25em;
	font-size: 14px;
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: $lightBlue;
	border-style: outset;
	border-radius: 0.125rem;
	background-color: rgba($darkGrey, 0.3) !important;
	font-family: "FontAwesome";
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0.125rem;
	margin-top: -1px;
}

.custom-control-label::after {
	width: 1.5em;
	height: 1.5em;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: black;
	margin-top: -2px;
	margin-left: -1.5px;
}

.rct-icon-leaf-close path {
	fill: url(#gradient-icons) $lightBlue !important;
}

.rct-icon-uncheck path {
	fill: $white;
	stroke: url(#gradient-icons) $lightBlue !important;
	stroke-width: 24;
}

.rct-icon-check path {
	fill: rgba($darkGrey, 0.3);
	stroke: url(#gradient-icons) $lightBlue !important;
	stroke-width: 24;
}

.rct-icon-half-check path {
	fill: rgba($darkGrey, 0.3);
	stroke: url(#gradient-icons) $lightBlue !important;
	stroke-width: 24;
}

.overflow-wrap {
	overflow-wrap: break-word;
}

//Dashboard

.hover-area {
	width: 100%;
	height: 80px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1010;
}

.zone-graphic-info {
	z-index: 999;
	background-color: $darkGrey;
	background-color: rgba($darkGrey, 0.8);
	color: $white;
	width: 100%;
}

.drag-drop-box {
	border: 2px dashed grey;
	border-radius: 10px;
	margin-right: 2%;
}

.dash-image {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 400px;
	margin-left: auto;
	margin-right: auto;
}

.dash-text {
	color: $darkGrey;
}

.dashboard-notifications {
	border: 1px solid $darkInfo;
}

.dashboard-notifications h3 {
	color: $darkInfo;
}

.sortable-text {
	font-size: 0.8rem;
}

#chart-blue {
	max-height: 290px;
}

#chart-multi {
	min-height: 390px;
	max-height: 390px;
}

.dash-border {
	border: 3px solid $lightBlue;
	@include inset-border-blue;
}

.dash-border-grey {
	border: 2px solid $lightGrey;
}

.dash-border-warning {
	border: 3px solid $mediumWarn;
	border-style: inset;
}

.dash-border-success {
	border: 3px solid $lightGreen;
	border-style: inset;
}

.dash-border-adjust {
	padding: 2px;
}

.dash-block-header {
	min-height: 32px;
}

.dash-card {
	background: $white;
	border: 3px dashed $mediumGrey;
	font-size: 1.1em;
	box-shadow: none;
}

// Statistics

#stats-report input {
	max-width: 180px;
}

#stats-report .react-datepicker-popper {
	min-width: 328px;
}

.icon-margin-fix {
	margin-right: -6px;
}

// Site Settings

.wizard-card {
	background: none;
	border: 3px dashed $mediumGrey;
	font-size: 1.1em;
	box-shadow: none;
}

.wizard-plus {
	font-size: 2em;
}

.settings-header {
	margin-top: -25px;
	font-size: 160px;
	text-align: center;
	max-height: 172px;
	overflow: hidden;
	box-shadow: inset rgba($darkBlue, 0.5) 0px -10px 10px -10px;
}

.menu-block-title {
	min-height: 46px;
}

.menu-block-description {
	min-height: 72px;
}

.advanced-options {
	text-align: center;
	padding: 12.5px;
	margin-top: 10px;
	color: $black;
}

//Site Layout
.building-icon {
	font-size: 100px;
	max-height: 60px;
	overflow: hidden;
	color: $lightBlue;
}

//Parameter Setup

.react-datepicker-wrapper input {
	border-radius: 4px;
	border: 1px solid $lightGreyBorder;
	padding: 0.3125rem 1rem;
}

//User Settings

.user-settings-banner-shadow {
	box-shadow: inset rgba($darkBlue, 0.5) 0px -10px 10px -10px;
}

//Statistics

.rounded-grid .react-grid-Grid {
	border-radius: 0.375rem !important;
}

.RRT__tabs {
	border-radius: 0.375rem;
}

.RRT__tab--first {
	border-radius: 0.375rem 0 0 0;
}

.react-grid-HeaderCell__draggable {
	cursor: ew-resize !important;
}

.dropdown-menu a {
	color: $darkestGrey;
}

.dropdown-item a:hover {
	text-decoration: none;
}

.dropdown-item:hover {
	.fa-download {
		color: $darkGreen;
	}
	.fa-print {
		color: $mediumBlue;
	}
	.fa-copy {
		color: $darkRed;
	}
	.fa-envelope-square {
		color: $mediumWarn;
	}
}

.preview-page {
	margin-top: 180px;
	font-size: 1.5rem;
	color: $mediumGrey;
}

.thick-border {
	border-width: 3px !important;
	border-style: dashed !important;
	border-color: $mediumGrey !important;
}

.drag-drop-box-chart-report label {
	cursor: pointer;
}

.avatar-name {
	border-color: $darkGrey !important;
}

.close-modal {
	cursor: pointer;
	font-family: $fontFamily;
}

.close-modal:hover {
	color: $darkRed;
}

.form-modal {
	overflow: hidden;
	iframe {
		scrollbar-width: 0;
	}
}

.zone-graphic-readings {
	cursor: pointer;
}

.dropdown-item.disabled {
	font-style: italic;
}

.MuiTableCell-root {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	vertical-align: top !important;
}

.MuiTable-root {
	width: auto !important;
}

.MuiPaper-root {
	width: auto !important;
}

.edit-pencil {
	color: #fff;
}

.site-layout-group:hover {
	.edit-pencil {
		color: $mediumGrey !important;
		cursor: pointer;
	}
}

.no-outline {
	color: $mediumGrey;
	background: none;
	border: none;
}
.distributor-card {
	min-width: 12rem;
}

.site-image-thumb {
	display: block;
	max-height: 100px;
	max-width: 90%;
	width: auto;
	height: auto;
}

.menu-minor {
	cursor: pointer;
}

.menu-minor:hover {
	text-decoration: none;
}

.ReactModal__Overlay--after-open {
	z-index: 9999 !important;
}

.site-image-link {
	cursor: pointer;
	background: none;
	border: none;
	max-width: 50%;
	img {
		display: block;
		max-width: 100%;
		width: auto;
		height: auto;
	}
}
.site-image-link:hover {
	background: none;
}

.site-image-link:active {
	background: none;
}

.shift-left {
	margin-left: -1.06rem;
	margin-right: 0.5rem;
	background: rgba(255, 255, 255, 0.7);
}

.shift-left-no-bg {
	margin-left: -1.06rem;
	margin-right: 0.5rem;
}

.table-submenu {
	color: #39afd1;
}

.no-button {
	cursor: pointer;
	background: none;
	border: none;
	color: $darkGrey;
	font-size: 1rem;
	padding: 0;
}

.no-button:hover {
	background: none;
	color: $darkGrey;
}

.no-button:active {
	background: none;
	background-color: inherit !important;
	color: $darkGrey !important;
}

.white-dots {
	color: $white;
}

.no-radius-right {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.degrees-button {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.distributor-site-image {
	min-height: 100px;
}

.distributor-site-name {
	min-height: 50px;
}

.widget-menu {
	cursor: pointer;
	z-index: 1000;
}

.widget-add-dropdown {
	width: 500%;
}

.widget-description {
	font-size: 0.95rem;
	text-align: center;
	color: black;
}
.widget-dropdown-block:hover {
	text-decoration: none;
}

.table-no-data {
	background-color: $lightGrey;
	height: 100%;
	width: 100%;
}

.placeholder-card {
	min-height: 100px;
}

.battery-missing {
	background-color: $lightGrey;
}

.battery-very-low {
	background-color: $lightRed;
	color: $white;
}

.battery-low {
	background-color: $lightWarn;
}

#chartOptions {
	font-family: $fontFamily;
}

.float-on-top {
	position: absolute;
	right: -3px;
	bottom: 4px;
	z-index: 9999;
	color: currentColor;
}

.edit-dropdown {
	position: absolute;
	right: -3px;
	bottom: 4px;
	z-index: 800;
}

.button-title {
	color: currentColor;
	font-weight: bold;
}

.button-title:focus,
.button-title.focus {
	box-shadow: none;
}

.rct-text span,
.rct-text label {
	font-family: $fontFamily;
	font-size: 0.83rem;
	font-weight: 400;
}
